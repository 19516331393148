<template>
  <div class="flex max-w-full py-6 overflow-hidden text-6xl lg:pt-7 lg:pb-14 lg:text-9xl"
       :class="theme"
  >
    <Motion :key="textWidth"
            :initial="{ x: 0 }"
            :animate="{ x: [0, -textWidth] }"
            :transition="{
              duration: animDuration,
              repeat: Infinity,
              easing: 'linear'
            }"
            class=""
    >
      <p ref="textContainer"
         class="leading-tight whitespace-pre"
         v-html="longText"
      />
    </Motion>
  </div>
</template>

<script setup lang="ts">
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Motion } from '@motionone/vue/dist/motion-vue.ssr.js';
import { computed, onMounted, ref } from 'vue';

const props = defineProps({
  backgroundColor: {
    type: String,
    default: 'dark'
  },
  text: {
    type: String,
    default: 'Asd'
  },
  dotColor: {
    type: String,
    default: 'current'
  }
});

const textContainer = ref(null);
const textWidth = ref(0);
const multiplier = ref(3);

// We add • between the text
const longText = computed(() => {
  const finalText = props.text + `<span class="text-${props.dotColor}"> • </span>`;
  return props.text.length > 0 ? finalText.repeat(multiplier.value) : '';
});

const animDuration = computed(() => {
  // calculate animation duration based on text length
  return props.text.length > 0 ? props.text.length / 2 : 1;
});

const theme = computed(() => {
  if (props.backgroundColor === 'light') return 'text-gray-600 bg-white';
  if (props.backgroundColor === 'dark') return 'text-white bg-gray-600';
  else return 'text-white bg-gray-600';
});

function calculateWidth() {
  if (!textContainer.value) {
    return;
  }

  const container = textContainer.value;
  const parentLength = container.parentNode.parentNode.offsetWidth;

  // to prevent divison by 0
  if (textWidth.value === 0) return;

  // calculate how many times to duplicate text to scroll 100% and still cover screen
  const newMultiplier = Math.ceil((parentLength + textWidth.value) / textWidth.value);

  if (newMultiplier !== multiplier.value) multiplier.value = newMultiplier;
}

onMounted(() => {
  textWidth.value = textContainer.value.offsetWidth / multiplier.value;
  calculateWidth();
});
</script>
